import './Header.scss';

export function Header(props) {

    let content = <div className="row">
        <div className="project-name col-12">
            Feedybacky Embedded Form
        </div>
    </div>;

    if(props.project) {
        content = <div className="row">
            <div className="project-icon col-5 col-md-4 col-lg-2 text-sm-center">
                <img src={ props.project.icon ?? 'assets/project_placeholder.png' } alt="Symbol projektu"/>
            </div>
            <div className="project-name col-7 col-md-8 col-lg-10">
                { props.project.name }
            </div>
        </div>;
    }

    return <div className="page-header row">
        <div className="page-title col-6">
            { content }
        </div>
    </div>;
}