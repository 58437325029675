import React, { Component } from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import './ActiveForm.scss';

class ActiveForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            project: props.project,

            message: '',
            image: null,
            email: '',
            extraInfo: '',

            errorMessage: '',
            errorEmail: '',
            errorTermsAccepted: '',
            errorPersonalDataAccepted: '',

            messageSuccess: '',
            messageError: ''
        };
    }

    componentDidMount() {
        
    }

    onMessageChange = (event) => {
        this.setState({message: event.target.value});
    }

    onEmailChange = (event) => {
        this.setState({email: event.target.value});
    }

    onExtraInfoChange = (event) => {
        this.setState({extraInfo: event.target.value});
    }
            
    encodeImageFileAsURL() {
        var filesSelected = document.getElementById('image').files;
        if(filesSelected.length > 0) {
            var fileToLoad = filesSelected[0];
            var fileReader = new FileReader();
            
            fileReader.onload = function(fileLoadedEvent) {
                var srcData = fileLoadedEvent.target.result; // <--- data: base64
                var newImage = document.createElement('img');
                newImage.id = 'base64-image';
                newImage.src = srcData;
                document.getElementById("img-preview").innerHTML = newImage.outerHTML;
            }
            fileReader.readAsDataURL(fileToLoad);
        }
    }

    clearScreenshot() {
        document.getElementById("img-preview").innerHTML = '';
    }

    validateForm() {
        const { t } = this.props; 
        const emailRegexp = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$";
		
        let isValidated = true;

        this.setState({
            errorMessage: '',
            errorEmail: '',
            errorTermsAccepted: '',
            errorPersonalDataAccepted: ''
        });

        if(this.state.message.length === 0) {
            this.setState({errorMessage: t('messageNotValidated')});
            isValidated = false;
        }
        if(this.state.project.fefEmailMode === 'required' &&  this.state.email.search(emailRegexp) != 0) {
            this.setState({errorEmail: t('emailNotValidated')});
            isValidated = false;
        }
        if(!document.getElementById('terms-accepted').checked) {
            this.setState({errorTermsAccepted: t('termsNotValidated')});
            isValidated = false;
        }
        if(!document.getElementById('personal-data-accepted').checked) {
            this.setState({errorPersonalDataAccepted: t('termsNotValidated')});
            isValidated = false;
        }

        if(!isValidated) {
            this.setState({messageError: t('errorMessage')});
        }

        return isValidated;
    }

    clearForm() {
        this.setState({
            message: '',
            image: null
        });
    }

    onSubmitForm = () => {
        const { t } = this.props; 

        this.setState({
            messageSuccess: '',
            messageError: ''
        });

        if(!this.validateForm()) {
            return;
        }

        let imageData = null;
        const imageNode = document.getElementById('base64-image');
        
        if(imageNode) {
            imageData = imageNode.src;
        }
                
        let payload = {
            message: this.state.message,
            timestamp: new Date(),
            url: window.location.href,
            projectSymbol: this.state.project.symbol,
            apiKey: this.state.project.apiKey,
            errors: []
        }
        
        if(imageData) {
            payload.image = imageData;
        }
		if(document.getElementById('metadata')!= null && document.getElementById('metadata').checked) {
			payload.agent = navigator.userAgent;
			payload.platform = navigator.platform;
			payload.screenSize = `${window.screen.width}x${window.screen.height}`;
			payload.availableScreenSize = `${window.screen.availWidth}x${window.screen.availHeight}`;
			payload.colorDepth = window.screen.colorDepth;
            payload.browserLanguage = navigator.language;
            payload.pixelRatio = window.devicePixelRatio;
		}

        if(this.state.email) {
            payload.email = this.state.email;
        }

        if(this.state.extraInfo) {
            payload.extraInfo = this.state.extraInfo;
        }
        
        fetch(process.env.REACT_APP_BACKEND_URL + 'issue/fef', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }).then(response => {
            if(response.status === 200 || response.status === 201) {
                this.setState({messageSuccess: t('sendingSuccess')});	
                this.clearForm();						
            }
            else {
                this.setState({messageError: t('sendingFailed')});
            }
        }).catch(e => {
            this.setState({messageError: t('sendingFailed')});
        });
    }

    render() {
        const { t } = this.props;   

        let emailHtml = '';
        if(['required', 'optional'].includes(this.state.project.fefEmailMode)) {
            emailHtml = <div className="form-group row">
                <label className="col-md-3 col-12 col-form-label" htmlFor="email">{ t('emailAddress') } { this.state.project.fefEmailMode === 'required' ? '*' : '' }</label>
                <div className="col-md-9 col-12">
                    <input type="text" className="form-control" id="email" rows="3" value={ this.state.email } onChange={ this.onEmailChange } />
                    <div className="form-error">{ this.state.errorEmail }</div>
                </div>
            </div>;
        }

        let screenshotHtml = '';
        if(this.state.project.isFefScreenshotEnabled) {
            screenshotHtml = <div>
                <div className="form-group row">
                    <label className="col-md-3 col-12 col-form-label" htmlFor="image">{ t('screenshot') }</label>
                    <div className="col-md-9 col-12">
                        <label className="form-file-label text-center" htmlFor="image">{ t('loadScreenshot') }</label>
                        <input type="file" className="form-control-file form-file" id="image" value="" accept="image/x-png,image/jpeg;capture=camera" onChange={ this.encodeImageFileAsURL } />
                        <span id="delete-image-link" onClick={ this.clearScreenshot }>{ t('clearScreenshot') }</span>
                    </div>
                </div>
                <div className="form-group row">
                    <div id="img-preview" className="col-md-9 offset-md-3 col-8 offset-0"></div>
                </div>
            </div>;
        }

        let metadataHtml = '';
        if(this.state.project.isFefMetadataCheckboxEnabled) {
            metadataHtml = <div className="form-group row">
                <label className="col-md-3 col-10 col-form-label" htmlFor="metadata">{ t('metadata') }</label>
                <div className="col-md-9 col-2">
                    <label className="checkbox-label" htmlFor="metadata">
                        <input className="form-checkbox-single" id="metadata" type="checkbox"/>
                        <span className="checkbox-custom rectangular"></span>
                    </label>
                    <div className="form-hint d-md-block d-none">
                        { t('metadataHint') }
                    </div>
                </div>
                <div className="col-12 form-hint d-md-none d-block">
                    { t('metadataHint') }
                </div>
            </div>;
        }

        let extraInfoHtml = '';
        if(this.state.project.isFefExtraInfoEnabled) {
            extraInfoHtml = <div className="form-group row">
                <label className="col-md-3 col-12 col-form-label" htmlFor="extra-info">{ t('extraInfo') }</label>
                <div className="col-md-9 col-12">
                    <textarea className="form-control" id="extra-info" rows="3" value={ this.state.extraInfo } onChange={ this.onExtraInfoChange }></textarea>
                    <div className="form-hint">
                        { t('extraInfoHint') }
                    </div>
                </div>
            </div>;
        }

        return (
            <div className="embedded-form">
                <Header project={ this.state.project }/>

                <div className="row">
                    <div className="col-12">
                        <div className="page-description">
                            { t('activeFormDescriptionFirst') } <b>{ this.state.project.name }</b> { t('activeFormDescriptionSecond') } <a href={ process.env.REACT_APP_FRONTEND_URL } target="_blank" rel="noreferrer">Feedybacky</a>. { t('activeFormDescriptionThird') }
                        </div>
                        <div className="issue-list-link">
                            <a href={ process.env.REACT_APP_FRONTEND_URL + 'project/' + this.state.project.symbol } target="_blank" rel="noreferrer">{ t('activeFormIssueListLink') }</a>
                        </div>
                    </div>

                    <form className="form-issue col-12" onSubmit={ this.onSubmitForm }>   

                        <div className="row">
                            <div className="col-12">
                                <div className="form-group row">
                                    <label className="col-md-3 col-12 col-form-label" htmlFor="message">{ t('message') } *</label>
                                    <div className="col-md-9 col-12">
                                        <textarea className="form-control" id="message" rows="3" value={ this.state.message } onChange={ this.onMessageChange }></textarea>
                                        <div className="form-error">{ this.state.errorMessage }</div>
                                    </div>
                                </div>

                                { screenshotHtml }

                                { emailHtml }

                                { extraInfoHtml }

                                { metadataHtml }

                                <div className="form-group row">
                                    <label className="col-md-11 col-10 col-form-label" htmlFor="terms-accepted">
                                        { t('termsAcceptedPreLink') } <a href={ this.state.project.fefTermsUrl } target="_blank" rel="noreferrer">{ t('terms') }</a>. *
                                    </label>
                                    <div className="col-md-1 col-2">
                                        <label className="checkbox-label" htmlFor="terms-accepted">
                                            <input className="form-checkbox-single" id="terms-accepted" type="checkbox"/>
                                            <span className="checkbox-custom rectangular"></span>
                                        </label>
                                    </div>
                                    <div className="col-12 form-error">{ this.state.errorTermsAccepted }</div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-md-11 col-10 col-form-label" htmlFor="personal-data-accepted">
                                        { t('personalDataAcceptedPreLink') } <a href={ this.state.project.fefPrivacyPolicyUrl } target="_blank" rel="noreferrer">{ t('privacyPolicy') }</a>. *
                                    </label>
                                    <div className="col-md-1 col-2">
                                        <label className="checkbox-label" htmlFor="personal-data-accepted">
                                            <input className="form-checkbox-single" id="personal-data-accepted" type="checkbox"/>
                                            <span className="checkbox-custom rectangular"></span>
                                        </label>
                                    </div>
                                    <div className="col-12 form-error">{ this.state.errorPersonalDataAccepted }</div>
                                </div>
                            </div>  

                            <div className="button-container col-8 offset-2 offset-lg-4 col-lg-4">
                                <button className="btn btn-title-button btn-main btn-lg" type="button" onClick={ this.onSubmitForm }>{ t('submit') }</button>
                            </div>
                        </div>   
                        
                        <div className="row message-container">
                            <div id="success-message" className="col-12 service-response-container success-service-response" style={{ display: this.state.messageSuccess ? 'block' : 'none' }}>
                                { this.state.messageSuccess }
                            </div>

                            <div id="error-message" className="col-12 service-response-container error-service-response" style={{ display: this.state.messageError ? 'block' : 'none' }}>
                                { this.state.messageError }
                            </div>
                        </div>                    
                    </form>
                </div>

                <Footer text={[ 
                    t('footerThanksTo'),
                    <a key="linkFeedybacky" href={ process.env.REACT_APP_FRONTEND_URL } target="_blank" rel="noreferrer">Feedybacky</a>, 
                    t('footerHandledBy'), 
                    <a key="linkCompany" href="http://www.wildasoftware.pl" target="_blank" rel="noreferrer">Wilda Software</a>, 
                    '.' 
                ]}/>
            </div>
        );
    }

}

export default ActiveForm;