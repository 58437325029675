import React, { Component, Suspense } from 'react';
import './App.scss';
import {withRouter} from "react-router-dom";
import ActiveForm from './pages/ActiveForm';
import FailedForm from './pages/FailedForm';
import WaitingForm from './pages/WaitingForm';
import { Navigation } from './components/Navigation';
import { withTranslation } from 'react-i18next';

class App extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            isError: false,
            project: null,
            isPending: true
        };
    }

    componentDidMount() {
        const key = this.props.match.params.key;
        
        if(key) {        
            fetch(process.env.REACT_APP_BACKEND_URL + 'project/fef/' + key)
                .then(res => res.json())
                .then(
                    (result) => {
                        if(result.statusCode === 404) {
                            this.setState({
                                isError: true,
                                errorMessage: '404 Not Found',
                                isPending: false
                            });
                        }
                        else {
                            this.setState({
                                project: result,
                                isPending: false
                            });
                        }
                    },
                    (error) => {
                        this.setState({
                            isError: true,
                            errorMessage: error,
                            isPending: false
                        });
                    }
                )
        }
        else {
            this.setState({
                isError: true,
                isPending: false
            });
        }
    }

    render() {
        const WatingFormComponent = withTranslation()(WaitingForm);
        const ActiveFormComponent = withTranslation()(ActiveForm);
        const FailedFormComponent = withTranslation()(FailedForm);

        let content = <WatingFormComponent />;

        if(this.state.project) {
            content = <ActiveFormComponent project={this.state.project} />
        }
        else if(this.state.isError) {
            content = <FailedFormComponent />
        }

        return (
            <div id="App">
                <Navigation />
                <div className="container container-content">    
                    <Suspense fallback="loading">
                        {content}
                    </Suspense>                
                </div>
            </div>
        );
    }

}

export default withRouter(App);