import './Footer.scss';

export function Footer(props) {
    
    return <div className="row footer">
        <a href={ process.env.REACT_APP_FRONTEND_URL } target="_blank" rel="noreferrer">
            <img src={ 'assets/feedybacky_horizontal.png' } alt="Feedybacky"/>
        </a>
        <span>
            { props.text }
        </span>
    </div>;
}